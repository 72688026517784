import {
  PurchaseOrderReducerState,
  PurchaseOrderActionTypes,
} from 'types/purchaseOrderStore';
import { PurchaseOrdersActionTypeEnum } from 'enums/actions';
import {
  DEFAULT_ROWS_PER_PAGE_PO_TABLE,
  TOTAG_LIST_DATA_TABLE_OPTIONS,
} from 'utils/defaultValues/dataTable';

export const initialState: PurchaseOrderReducerState = {
  error: null,
  toTag: {
    params: {
      recordsPerpage: TOTAG_LIST_DATA_TABLE_OPTIONS.rowsPerPage,
      pageNumber: TOTAG_LIST_DATA_TABLE_OPTIONS.page,
    },
    data: undefined,
    suppliers: [],
    vessels: [],
  },
  tagged: {
    params: {
      recordsPerpage: TOTAG_LIST_DATA_TABLE_OPTIONS.rowsPerPage,
      pageNumber: TOTAG_LIST_DATA_TABLE_OPTIONS.page,
    },
    data: undefined,
    suppliers: [],
    vessels: [],
  },
  delivered: {
    params: {
      recordsPerpage: TOTAG_LIST_DATA_TABLE_OPTIONS.rowsPerPage,
      pageNumber: TOTAG_LIST_DATA_TABLE_OPTIONS.page,
    },
    data: undefined,
    suppliers: [],
    vessels: [],
  },
  inTransit: {
    params: {
      recordsPerpage: TOTAG_LIST_DATA_TABLE_OPTIONS.rowsPerPage,
      pageNumber: TOTAG_LIST_DATA_TABLE_OPTIONS.page,
    },
    data: undefined,
    suppliers: [],
    vessels: [],
  },
  analytics: {
    POsTagged: 0,
    lineItemTagged: 0,
    POsPartiallyTagged: 0,
    POsPendingCheckin: 0,
    POsCompletelyStored: 0,
    POsPartiallyStored: 0,
  },
  reasonsSkipTagging: [],
  searchParams: {
    pageNumber: 1,
    recordsPerpage: DEFAULT_ROWS_PER_PAGE_PO_TABLE,
  },
  tabCounters: [],
};

export const purchaseOrderReducer = (
  state = initialState,
  action: PurchaseOrderActionTypes,
): PurchaseOrderReducerState => {
  switch (action.type) {
    case PurchaseOrdersActionTypeEnum.READ_LIST_TO_TAG_PURCHASE_ORDERS_REQUEST:
      return {
        ...state,
        toTag: {
          ...state.toTag,
          params: {
            ...state.toTag.params,
            ...action.payload,
          },
        },
      };
    case PurchaseOrdersActionTypeEnum.READ_LIST_TAGGED_PURCHASE_ORDERS_REQUEST:
      return {
        ...state,
        tagged: {
          ...state.tagged,
          params: {
            ...state.tagged.params,
            ...action.payload,
          },
        },
      };
    case PurchaseOrdersActionTypeEnum.READ_LIST_DELIVERED_PURCHASE_ORDERS_REQUEST:
      return {
        ...state,
        delivered: {
          ...state.delivered,
          params: {
            ...state.delivered.params,
            ...action.payload,
          },
        },
      };
    case PurchaseOrdersActionTypeEnum.READ_LIST_TO_TAG_PURCHASE_ORDERS_SUCCESS:
      return {
        ...state,
        toTag: {
          ...state.toTag,
          data: action.payload,
        },
      };
    case PurchaseOrdersActionTypeEnum.READ_LIST_TAGGED_PURCHASE_ORDERS_SUCCESS:
      return {
        ...state,
        tagged: {
          ...state.tagged,
          data: action.payload,
        },
      };
    case PurchaseOrdersActionTypeEnum.READ_LIST_DELIVERED_PURCHASE_ORDERS_SUCCESS:
      return {
        ...state,
        delivered: {
          ...state.delivered,
          data: action.payload,
        },
      };
    case PurchaseOrdersActionTypeEnum.GET_TAGGED_PURCHASE_ORDERS_SUCCESS:
      return {
        ...state,
        analytics: {
          ...state.analytics,
          POsTagged: action.payload?.value,
        },
      };
    case PurchaseOrdersActionTypeEnum.GET_TAGGED_LINE_ITEM_COUNT_SUCCESS:
      return {
        ...state,
        analytics: {
          ...state.analytics,
          lineItemTagged: action.payload?.value,
        },
      };
    case PurchaseOrdersActionTypeEnum.GET_PARTIALLY_TAGGED_PURCHASE_ORDERS_SUCCESS:
      return {
        ...state,
        analytics: {
          ...state.analytics,
          POsPartiallyTagged: action.payload?.value,
        },
      };
    case PurchaseOrdersActionTypeEnum.GET_PENDING_CHECKIN_PURCHASE_ORDERS_SUCCESS:
      return {
        ...state,
        analytics: {
          ...state.analytics,
          POsPendingCheckin: action.payload?.value,
        },
      };
    case PurchaseOrdersActionTypeEnum.GET_COMPLETELY_STORED_PURCHASE_ORDERS_SUCCESS:
      return {
        ...state,
        analytics: {
          ...state.analytics,
          POsCompletelyStored: action.payload?.value,
        },
      };
    case PurchaseOrdersActionTypeEnum.GET_PARTIALLY_STORED_PURCHASE_ORDERS_SUCCESS:
      return {
        ...state,
        analytics: {
          ...state.analytics,
          POsPartiallyStored: action.payload?.value,
        },
      };
    case PurchaseOrdersActionTypeEnum.GET_SEARCH_PURCHASE_ORDERS_SUCCESS:
      return {
        ...state,
        pendingCheckinDetail: action.payload,
      };
    case PurchaseOrdersActionTypeEnum.LOAD_MORE_PURCHASE_ORDERS_ACTION:
      return {
        ...state,
        pendingCheckinDetail: {
          totalCount: action.payload.totalCount,
          totalPages: action.payload.totalPages,
          aggregation: action.payload.aggregation,
          pageNumber: action.payload.pageNumber,
          data: [
            ...(state.pendingCheckinDetail?.data ?? []),
            ...action.payload.data,
          ],
        },
      };
    case PurchaseOrdersActionTypeEnum.READ_LIST_IN_TRANSIT_PURCHASE_ORDERS_REQUEST:
      return {
        ...state,
        inTransit: {
          ...state.inTransit,
          params: {
            ...state.inTransit.params,
            ...action.payload,
          },
        },
      };
    case PurchaseOrdersActionTypeEnum.READ_LIST_IN_TRANSIT_PURCHASE_ORDERS_SUCCESS:
      return {
        ...state,
        inTransit: {
          ...state.inTransit,
          data: action.payload,
        },
      };
    case PurchaseOrdersActionTypeEnum.REMOVE_TAGGED_PURCHASE_ORDER:
      return {
        ...state,
        tagged: {
          ...state.tagged,
          data: state.tagged.data?.filter(
            (tag) => tag.id !== action.payload.id,
          ),
        },
      };
    case PurchaseOrdersActionTypeEnum.READ_LIST_REASONS_SKIP_TAGGING_SUCCESS:
      return {
        ...state,
        reasonsSkipTagging: action.payload,
      };
    case PurchaseOrdersActionTypeEnum.READ_SUPPLIER_FILTER_OPTIONS_SUCCESS:
      return {
        ...state,
        [action.tab]: {
          ...state[action.tab],
          suppliers: action.payload,
        },
      };
    case PurchaseOrdersActionTypeEnum.READ_VESSEL_FILTER_OPTIONS_SUCCESS:
      return {
        ...state,
        [action.tab]: {
          ...state[action.tab],
          vessels: action.payload,
        },
      };
    case PurchaseOrdersActionTypeEnum.RESET_PURCHASE_ORDER_ACTION_SUCCESS:
      return {
        ...state,
        [action.payload]: {
          params: {
            recordsPerpage: TOTAG_LIST_DATA_TABLE_OPTIONS.rowsPerPage,
            pageNumber: TOTAG_LIST_DATA_TABLE_OPTIONS.page,
          },
          data: undefined,
          suppliers: [],
          vessels: [],
        },
      };
    case PurchaseOrdersActionTypeEnum.SEARCH_PURCHASE_ORDERS_REQUEST:
      return {
        ...state,
        [action.tab]: {
          ...state[action.tab],
          params: {
            ...state[action.tab].params,
            ...action.payload,
          },
        },
      };
    case PurchaseOrdersActionTypeEnum.SEARCH_PURCHASE_ORDERS_SUCCESS:
      return {
        ...state,
        searchParams: action.params,
        tabCounters: action.payload.aggregation,
        [action.tab]: {
          ...state[action.tab],
          purchaseOrders: action.payload,
        },
      };
    default:
      return state;
  }
};
