export const DATA_TABLE_OPTIONS = {
  page: 0,
  rowsPerPage: 10,
  rowsPerPageOptions: [10, 20, 30],
};

export const DEFAULT_ROWS_PER_PAGE_PO_TABLE = 25;

export const ROWS_PER_PAGE_OPTIONS_PO_TABLE = [25, 50, 100];

export const TOTAG_LIST_DATA_TABLE_OPTIONS = {
  ...DATA_TABLE_OPTIONS,
  rowsPerPage: DEFAULT_ROWS_PER_PAGE_PO_TABLE,
};

export const DEFAULT_START_PAGE_NUMBER_OF_API = 1;
