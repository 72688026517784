export enum CopyrightEnum {
  COPYRIGHT_ICON = '© ',
}

export enum SparesEnum {
  SPARES_URL = 'https://www.sparescnx.com/',
}

export enum SecureStorageEnum {
  ACCESS_TOKEN = 'accessToken',
  REFRESH_TOKEN = 'refreshToken',
  VESSEL_ID = 'vessel_id',
  DECRYPT_INFO = 'decrypt_info',
  ACCOUNT_TYPE = 'accountType',
  ACCOUNT_CREATION_DATE = 'accountCreationDate',
}

export enum AccountType {
  FREIGHT_FORWARDERS = 'freight-forwarders',
  SUPPLIER = 'supplier',
}

export enum AuthErrorCodeEnum {
  NoLoginPermission = '1000',
}

export enum SetupPasswordFormTypeEnum {
  SETUP_PASSWORD = 'setup-password',
  RESET_PASSWORD = 'reset-password',
}

export enum ResetPasswordMsgEnum {
  ExpiredResetLinkMsg = 'jwt expired',
}
