import { RootStateType as IStore } from 'types/store';
import {
  PurchaseOrderStatusReducerPropertyEnum,
  PurchaseOrderStatusEnum,
} from 'enums/purchaseOrder';

// AppReducer
export const sTaskStatus = (key: string) => (store: IStore) =>
  store.asyncTaskReducer.status[key];

// Global Search Reducer
export const sGetGlobalSearchList = (store: IStore) =>
  store.globalSearchReducer.globalSearchList;

// Purchase Orders Reducer
export const sGetToTag = (store: IStore) => store.purchaseOrderReducer.toTag;
export const sGetTagged = (store: IStore) => store.purchaseOrderReducer.tagged;
export const sGetDelivered = (store: IStore) =>
  store.purchaseOrderReducer.delivered;
export const sGetTaggedPOs = (store: IStore) =>
  store.purchaseOrderReducer.analytics.POsTagged;
export const sGetTaggedLineItemCount = (store: IStore) =>
  store.purchaseOrderReducer.analytics.lineItemTagged;
export const sGetPartiallyTaggedPOs = (store: IStore) =>
  store.purchaseOrderReducer.analytics.POsPartiallyTagged;
export const sGetPendingCheckinPOs = (store: IStore) =>
  store.purchaseOrderReducer.analytics.POsPendingCheckin;
export const sGetCompletelyStoredPOs = (store: IStore) =>
  store.purchaseOrderReducer.analytics.POsCompletelyStored;
export const sGetPartiallyStoredPOs = (store: IStore) =>
  store.purchaseOrderReducer.analytics.POsPartiallyStored;
export const sGetPendingCheckinDetail = (store: IStore) =>
  store.purchaseOrderReducer.pendingCheckinDetail;
export const sHasMorePendingCheckinDetail = (store: IStore) => {
  return (
    (store.purchaseOrderReducer.pendingCheckinDetail?.totalPages ?? 0) >
    (store.purchaseOrderReducer.pendingCheckinDetail?.pageNumber ?? 0)
  );
};
export const sGetInTransit = (store: IStore) =>
  store.purchaseOrderReducer.inTransit;
export const sGetReasonsSkipTagging = (store: IStore) =>
  store.purchaseOrderReducer.reasonsSkipTagging;
export const sGetPurchaseOrdersSearchBarValue = (store: IStore) =>
  store.purchaseOrderReducer.searchParams.criteria;
export const sGetSearchPurchaseOrdersParams = (store: IStore) =>
  store.purchaseOrderReducer.searchParams;
export const sGetTabCounters = (store: IStore) =>
  store.purchaseOrderReducer.tabCounters;
// for filter po
export const sGetSuppliersOptions = (
  prop: PurchaseOrderStatusReducerPropertyEnum,
) => (store: IStore) => store.purchaseOrderReducer[prop].suppliers || [];
export const sGetSelectedSuppliers = (
  prop: PurchaseOrderStatusReducerPropertyEnum,
) => (store: IStore) =>
  store.purchaseOrderReducer[prop].params.selectedSuppliers;
export const sGetVesselOptions = (
  prop: PurchaseOrderStatusReducerPropertyEnum,
) => (store: IStore) => store.purchaseOrderReducer[prop].vessels || [];
export const sGetSelectedVessels = (
  prop: PurchaseOrderStatusReducerPropertyEnum,
) => (store: IStore) => store.purchaseOrderReducer[prop].params.selectedVessels;
export const sGetStartDeliveryDate = (
  prop: PurchaseOrderStatusReducerPropertyEnum,
) => (store: IStore) =>
  store.purchaseOrderReducer[prop].params.startDeliveryDate;
export const sGetEndDeliveryDate = (
  prop: PurchaseOrderStatusReducerPropertyEnum,
) => (store: IStore) => store.purchaseOrderReducer[prop].params.endDeliveryDate;
export const sGetTagStatusParam = (
  prop: PurchaseOrderStatusReducerPropertyEnum,
) => (store: IStore) =>
  store.purchaseOrderReducer[prop].params.status || PurchaseOrderStatusEnum.All;

// LoginReducer
export const sAuthenticated = (store: IStore) => store.loginReducer.status;
export const sLoginUser = (store: IStore) => store.loginReducer.user;

// Notification
export const sGetNotifications = (store: IStore) =>
  store.notificationReducer.notifications;

// dashboard reducer
// stat panel dashboard
export const sGetTotalWarehouse = (store: IStore) =>
  store.dashboardReducer.totalWarehouse;
export const sGetTotalVessel = (store: IStore) =>
  store.dashboardReducer.totalVessel;

// Get POs pending delivery data
export const sGetPendingCheckinData = (store: IStore) =>
  store.dashboardReducer.pendingCheckin;

// Version
export const sGetVersionList = (store: IStore) =>
  store.versionReducer.versionList?.data;
export const sGetVersionLastest = (store: IStore) =>
  store.versionReducer.versionList?.data?.[0]?.version;
