export enum PurchaseOrderFieldEnum {
  REFERENCE_NUMBER = 'referenceNumber',
  SUPPLIER = 'supplierName',
  VESSEL_NAME = 'vesselName',
  DELIVERY_DATE = 'deliveryDate',
  ORDERED_DATE = 'orderDate',
  DESCRIPTION = 'description',
  ACTION = 'action',
}

export enum AssignedTagsFieldEnum {
  REMAINING_QUANTITY = 'remainingQuantity',
  TAG_LABEL = 'tagLabel',
}

export enum LineItemFieldEnum {
  SPARE_DETAIL = 'spareDetail',
  TAG_ID = 'tagId',
  STATUS = 'status',
  ACTIONS = 'actions',
  ITEM_ORDER = 'itemOrder',
}

export enum ReasonSkipTaggingCodeEnum {
  OTHER_REASON = 'skip_tagging_rsn_5',
}

export enum PurchaseOrderTabLabelEnum {
  ToTag = 'To Tag',
  ReadyToShip = 'Ready to ship',
  Processed = 'Processed',
  InTransit = 'In transit',
  StoredOnVessel = 'Stored on vessel',
}

export enum PurchaseOrderStatusReducerPropertyEnum {
  ToTag = 'toTag',
  Tagged = 'tagged',
  InTransit = 'inTransit',
  Delivered = 'delivered',
}

export enum PurchaseOrderStatusLabelEnum {
  All = 'All',
  Pristine = 'Untagged',
  PartialTagged = 'Partially Tagged',
  Tagged = 'Fully Tagged',
  Skipped = 'Skipped',
  CheckedIn = 'Fully Stored',
  PartialCheckedIn = 'Partially Stored',
}

export enum PurchaseOrderStatusEnum {
  All = '',
  CheckedIn = 'Checked In',
  Tagged = 'Tagged',
  Error = 'Error',
  Pristine = 'Pristine',
  PartialTagged = 'Partial Tagged',
  PartialCheckedIn = 'Partial Checked In',
  Consumed = 'Consumed',
  PartialConsumed = 'Partial Consumed',
}

export enum PurchaseOrderMetadataNameEnum {
  TransitionCategory = 'transitionCategory',
}

export enum PurchaseOrderTransitionCategoryEnum {
  ToTag = 'to-tag',
  ReadyToShip = 'ready-to-ship',
  InTransit = 'in-transit',
  StoredOnVessel = 'stored-on-vessel',
}
